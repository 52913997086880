<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="산업안전보건위원회 회의록 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                      <c-btn v-if="popupParam.proceedingsId && editable" label="삭제" icon="delete_forever" @btnClicked="removeData" />
                      <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  label="회의제목"
                  name="proceedingsTitle"
                  v-model="data.proceedingsTitle">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :editable="editable"
                  label="회의일"
                  name="proceedingsDate"
                  v-model="data.proceedingsDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :rows="4"
                  label="회의내용"
                  name="proceedingsContents"
                  v-model="data.proceedingsContents">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="inTable"
              title="내부참석자 목록"
              :columns="inGrid.columns"
              gridHeight="250px"
              :data="data.attendeeInModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              noDataLabel="내부참석자를 추가하세요."
              :hideBottom="true"
              :editable="editable"
              :checkClickFlag="false"
              :isExcelDown="false"
              rowKey="proceedingsAttendeeId"
              selection="multiple"
            >
              <template slot="table-button">
                <q-btn-group outline>
                  <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addInAttendee" />
                  <c-btn v-if="editable && data.attendeeInModels.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="removeInAttendee" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="outTable"
              title="외부참석자 목록"
              :columns="outGrid.columns"
              gridHeight="250px"
              :data="data.attendeeOutModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              noDataLabel="외부참석자를 추가하세요."
              :hideBottom="true"
              :checkClickFlag="false"
              :isExcelDown="false"
              :editable="editable"
              rowKey="proceedingsAttendeeId"
              selection="multiple"
            >
              <template slot="table-button">
                <q-btn-group outline>
                          <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addOutAttendee" />
                          <c-btn v-if="editable && data.attendeeOutModels.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="removeOutAttendee" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
        </div>  
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="관련 회의록" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-sm-12 col-md-12 col-lg-12" style="height: 460px">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="관련 회의록">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'proceedings-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        proceedingsId: '',
      }),
    },
  },
  data() {
    return {

      attachable: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'OSH_PROCEEDINGS',
        taskKey: '',
      },
      saveUrl: transactionConfig.sai.osh.proceedings.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      data: {
        proceedingsId: '',
        plantCd: '',
        proceedingsDate: '',
        proceedingsContents: '',
        proceedingsTitle: '',
        regUserId: '',
        chgUserId: '',
        
        attendeeInModels: [],
        attendeeOutModels: [],
        deleteAttendeeInModels: [],
        deleteAttendeeOutModels: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      inGrid: {
        columns: [
          {
            name: 'attendeeDeptName',
            field: 'attendeeDeptName',
            label: '부서',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'attendeeUserName',
            field: 'attendeeUserName',
            label: '이름',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'attendeeJobName',
            field: 'attendeeJobName',
            label: '직책',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'center',
            style: 'width: 200px',
          },
        ],
        data: [],
      },
      outGrid: {
        columns: [
          {
            name: 'attendeeBelong',
            field: 'attendeeBelong',
            label: '소속',
            type: 'text',
            align: 'center',
            style: 'width: 80px',
            required: true,
          },
          {
            name: 'attendeeUserName',
            field: 'attendeeUserName',
            label: '이름',
            style: 'width: 80px',
            type: 'text',
            required: true,
            align: 'center',
          },
          {
            name: 'attendeeJobName',
            field: 'attendeeJobName',
            label: '직책',
            type: 'text',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'center',
            style: 'width: 200px',
          },
        ],
        data: [],
      },
      realKey: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.data.proceedingsDate = this.$comm.getToday();
      // url setting
      this.detailUrl = selectConfig.sai.osh.proceedings.get.url;
      this.insertUrl = transactionConfig.sai.osh.proceedings.insert.url;
      this.updateUrl = transactionConfig.sai.osh.proceedings.update.url;
      this.deleteUrl = transactionConfig.sai.osh.proceedings.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.proceedingsId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.proceedingsId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.proceedingsId)
          this.attachable = true;
        },);
      }
    },
    saveData() {
      if (this.popupParam.proceedingsId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let selectData = this.data.attendeeOutModels;
          let isConti = true;
          let checkItem = ['attendeeBelong', 'attendeeUserName']
          this.$_.forEach(selectData, item => {
            this.$_.forEach(checkItem, check => {
              if (!item[check]) {
                isConti = false;
                return false;
              }
            })
          }); 
          if (!isConti) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '필수 입력값을 입력해 주세요. [소속, 이름]',
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인', 
              message: '저장하시겠습니까?',  
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId;
                this.data.chgUserId = this.$store.getters.user.userId;
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.proceedingsId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.proceedingsId = result.data
      this.$set(this.attachInfo, 'taskKey', this.popupParam.proceedingsId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    addInAttendee() {
      this.popupOptions.title = "내부참석자 검색"; 
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;

    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.data.attendeeInModels, {
            attendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.data.attendeeInModels.splice(0, 0, {
              proceedingsId: this.popupParam.proceedingsId,
              proceedingsAttendeeId: uid(),
              attendeeType: '1',
              attendeeUserId: _item.userId,
              attendeeUserName: _item.userName,
              attendeeDeptName: _item.deptName,
              attendeeJobName: _item.jobName,
              remark: '',
              chgUserId: this.$store.getters.user.userId,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeInAttendee() {
      let selectData = this.$refs['inTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.data.deleteAttendeeInModels) {
            this.data.deleteAttendeeInModels = []
          }
          if (this.$_.findIndex(this.data.deleteAttendeeInModels, { proceedingsAttendeeId: item.proceedingsAttendeeId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteAttendeeInModels.push(item)
          }
          this.data.attendeeInModels = this.$_.reject(this.data.attendeeInModels, item)
        })
      }
    },
    addOutAttendee() {
      this.data.attendeeOutModels.splice(0, 0, {
        proceedingsId: this.popupParam.proceedingsId,
        proceedingsAttendeeId: uid(),
        attendeeType: '2',
        attendeeUserId: '',
        attendeeUserName: '',
        attendeeJobName: '',
        remark: '',
        attendeeBelong: '',
        chgUserId: this.$store.getters.user.userId,
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeOutAttendee() {
      let selectData = this.$refs['outTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.data.deleteAttendeeOutModels) {
            this.data.deleteAttendeeOutModels = []
          }
          if (this.$_.findIndex(this.data.deleteAttendeeOutModels, { proceedingsAttendeeId: item.proceedingsAttendeeId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteAttendeeOutModels.push(item)
          }
          this.data.attendeeOutModels = this.$_.reject(this.data.attendeeOutModels, item)
        })
      }
    },
  }
};
</script>
